var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"facility-view-tab-inventory"},[_c('v-card',{staticClass:"mb-7"},[_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.facilityData.inventory,"hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"item.facility",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:_vm.facilityData.icon ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":_vm.facilityData.icon ? '' : 'primary',"size":"32"}},[(_vm.facilityData.icon)?_c('v-img',{attrs:{"src":require(("@/assets/images/facilities/" + (_vm.facilityData.icon)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(_vm.facilityData.name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'facility-view', params : { id : _vm.facilityData.id } }}},[_vm._v(" "+_vm._s(_vm.facilityData.name)+" ")]),_c('small',[_vm._v(_vm._s(_vm.facilityData.location))])],1)],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("21/11/2021")])]}},{key:"item.batch",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-semibold text--primary"},[_vm._v(_vm._s(item.batch_no))])]}},{key:"item.received",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center font-weight-semibold text--primary"},[_vm._v(_vm._s(_vm.formatAccounting(item.quantity_received)))])]}},{key:"item.issued",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatAccounting(item.quantity_used)))])]}},{key:"item.returned",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatAccounting(item.quantity_returned)))])]}},{key:"item.available",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center font-weight-semibold text--primary"},[_vm._v(_vm._s(_vm.formatAccounting(item.quantity_holding)))])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('vue-apex-charts',{attrs:{"type":"radialBar","options":_vm.getChartConfig(item),"series":[_vm.resolveProgressValue(item)],"height":"90","width":"60"}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }