<template>
  <div
    class="facility-view-tab-inventory"
  >
    <!-- user project list -->
    <v-card
      class="mb-7"
    >
      <v-data-table
        :headers="tableColumnHeaders"
        :items="facilityData.inventory"
        hide-default-footer
        show-select
      >
        <!-- project -->
        <template #[`item.facility`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="facilityData.icon ? '' : 'primary'"
              :class="facilityData.icon ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="facilityData.icon"
                :src="require(`@/assets/images/facilities/${facilityData.icon}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(facilityData.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'facility-view', params : { id : facilityData.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ facilityData.name }}
              </router-link>
              <small>{{facilityData.location}}</small>
            </div>
          </div>
        </template>

        <!-- date -->
        <template #[`item.date`]="{item}">
          <span>21/11/2021</span>
        </template>

        <!-- provider -->
        <template #[`item.batch`]="{item}">
          <span class="font-weight-semibold text--primary">{{ item.batch_no }}</span>
        </template>

        <!-- received -->
        <template #[`item.received`]="{item}">
          <div class="text-center font-weight-semibold text--primary">{{ formatAccounting(item.quantity_received) }}</div>
        </template>

        <!-- issued -->
        <template #[`item.issued`]="{item}">
          <div class="text-center">{{ formatAccounting(item.quantity_used) }}</div>
        </template>

        <!-- received -->
        <template #[`item.returned`]="{item}">
          <div class="text-center">{{ formatAccounting(item.quantity_returned) }}</div>
        </template>

        <!-- available -->
        <template #[`item.available`]="{item}">
          <div class="text-center font-weight-semibold text--primary">{{ formatAccounting(item.quantity_holding) }}</div>
        </template>

        <!-- progress -->
        <template #[`item.progress`]="{item}">
          <vue-apex-charts
            type="radialBar"
            :options="getChartConfig(item)"
            :series="[resolveProgressValue(item)]"
            height="90"
            width="60"
          ></vue-apex-charts>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    facilityData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const tableColumnHeaders = [
      {
        text: 'FACILITY',
        value: 'facility',
      },
      { text: 'BATCH', value: 'batch' },
      { text: 'ISSUED ON', value: 'date' },
      { text: 'RECEIVED', value: 'received', sortable: false },
      { text: 'ISSUED', value: 'issued', sortable: false },
      { text: 'RETURNED', value: 'returned', sortable: false },
      { text: 'AVAILABLE', value: 'available', sortable: false },
      { text: 'PROGRESS', value: 'progress', sortable: false },
    ]

    const chartOptions = {
      chart: {
        sparkline: {
          enabled: false,
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '30%',
          },
          track: {
            background: '#ebe9f1',
          },
          dataLabels: {
            show: false,
            name: {
              show: false,
            },
          },
        },
      },

      grid: {
        padding: {
          bottom: -15,
        },
      },
    }

    const getChartConfig = item => {
      const options = JSON.parse(JSON.stringify(chartOptions))
      options.colors = [resolveProgressColor(item)]

      return options
    }

    const resolveProgressValue = item => {
      var received = item.quantity_received ?? 0
      var available = item.quantity_holding ?? 0

      if (available == 0 || received == available) return 1
      return Math.round(((received - available) / received) * 100)
    }

    const resolveProgressColor = item => {
      var progress = resolveProgressValue(item)
      if (progress <= 25) return '#ff4c51'
      if (progress > 25 && progress <= 50) return '#ffb400'
      if (progress > 50 && progress <= 75) return '#9155fd'
      if (progress > 75) return '#56ca00'

      return '#656971'
    }

    const formatAccounting = value => {
      return (value ?? 0).toString().toAccounting()
    }

    String.prototype.toAccounting = function () {
      return parseFloat(this)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }

    return {
      tableColumnHeaders,
      formatAccounting,
      getChartConfig,
      resolveProgressValue,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
