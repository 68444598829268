<template>
  <div
    class="facility-view-tab-overview"
  >
    <!-- user project list -->
    <v-card
      class="mb-7"
    >
      <v-card-title>
        Corporate Groups Assigned
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="projectList"
        hide-default-footer
      >
        <!-- project -->
        <template #[`item.corporate`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="33"
              class="me-3"
            >
              <v-img :src="require(`@/assets/images/icons/project-icons/${item.logo}`)"></v-img>
            </v-avatar>
            <div class="text-no-wrap">
              <p class="font-weight-medium text--primary mb-n1">
                {{ item.name }}
              </p>
              <span class="text-xs text--disabled">{{ item.corporate }}</span>
            </div>
          </div>
        </template>

        <!-- progress -->
        <template #[`item.progress`]="{item}">
          <span>{{ item.progress }}%</span>
          <v-progress-linear
            height="6"
            rounded
            class="project-progress mt-1"
            :color="resolveUserProgressVariant(item.progress)"
            :value="item.progress"
          ></v-progress-linear>
        </template>

        <!-- provider -->
        <template #[`item.provider`]="{item}">
          <span>{{ facilityData.name }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js'

export default {
  props: {
    facilityData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const tableColumnHeaders = [
      {
        text: 'CORPORATES',
        value: 'corporate',
        sortable: false,
      },
      { text: 'LOCATION', value: 'location', sortable: false },
      { text: 'PROVIDER', value: 'provider', sortable: false },
      { text: 'QUANTITY', value: 'vaccinated', sortable: false },
      { text: 'PROGRESS', value: 'progress', sortable: false },
      { text: 'PAID', value: 'paid', sortable: false },
    ]
    const projectList = [
      {
        logo: 'python.png',
        name: 'TechTitans Limited',
        corporate: 'Technology Company',
        location: 'Westlands, Nairobi',
        vaccinated: '122/240',
        progress: 78,
        paid: '$18.42',
      },
      {
        logo: 'ponea.png',
        name: 'Ponea Health BV',
        corporate: 'Digital Health',
        location: 'Riverside, Nairobi',
        vaccinated: '9/56',
        progress: 18,
        paid: '$20.42',
      },
      {
        logo: 'ncba.png',
        name: 'NCBA PLC',
        corporate: 'Commercial Bank',
        location: 'Upperhill, Nairobi',
        vaccinated: '290/320',
        progress: 62,
        paid: '$120.87',
      },
      {
        logo: 'xamarin.png',
        name: 'Foodista Delivery',
        corporate: 'Booking',
        location: 'Lamu',
        vaccinated: '20/301',
        progress: 8,
        paid: '$120.87',
      },
    ]

    const resolveUserProgressVariant = progrss => {
      if (progrss <= 25) return 'error'
      if (progrss > 25 && progrss <= 50) return 'warning'
      if (progrss > 50 && progrss <= 75) return 'primary'
      if (progrss > 75 && progrss <= 100) return 'success'

      return 'secondary'
    }

    return {
      tableColumnHeaders,
      projectList,
      resolveUserProgressVariant,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
