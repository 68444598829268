<template>
  <v-row class="facility-view-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="facilityData.icon ? '' : 'primary'"
            :class="facilityData.icon ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="facilityData.icon"
              :src="require(`@/assets/images/facilities/${facilityData.icon}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(facilityData.name) }}</span>
          </v-avatar>

          <span
            class="mb-2"
            style="font-size:0.9em"
          >{{ facilityData.name }}</span>

          <v-chip
            label
            small
            color="secondary"
            class="v-chip-light-bg text-sm font-weight-semibold success--text"
            style="font-size:0.9em"
          >
            KMHFL: {{ facilityData.kmhfl_code }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Location:</span>
              <span class="text--secondary">{{ facilityData.location }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Contact:</span>
              <span class="text--secondary">+254 7X XXX XXXX</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
              v-if="branchAvailable"
            >
              <span class="font-weight-medium me-2">Branches:</span>
              <span class="text--secondary">{{branchCount}} Branch{{branchCount == 1 ? '': 'es'}}</span>
            </v-list-item>

            <v-divider
              class="mt-3"
              v-if="parentAvailable"
            ></v-divider>
            <v-list-item
              dense
              class="px-0 mb-n2"
              v-if="parentAvailable"
            >
              <span class="font-weight-medium me-2">Parent:</span>
              <span class="text--secondary text-capitalize">{{ facilityData.parent[0].name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
              v-if="parentAvailable"
            >
              <span class="font-weight-medium me-2">Location:</span>
              <span class="text--secondary text-capitalize">{{ facilityData.parent[0].location }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'

export default {
  components: {},
  props: {
    facilityData: {
      type: Object,
      required: true,
    },
    parentAvailable: {
      type: Boolean,
      required: true,
    },
    branchAvailable: {
      type: Boolean,
      required: true,
    },
    branchCount: {
      type: Number,
      required: true,
    },
  },

  setup() {
    return {
      avatarText,
      kFormatter,

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
