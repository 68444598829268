<template>
  <div id="facility-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="3"
      >
        <facility-view-bio-panel
          :facility-data="facilityData"
          :parent-available="parentAvailable"
          :branch-available="branchAvailable"
          :branch-count="branchCount"
        ></facility-view-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="9"
      >
        <v-tabs
          v-model="facilityTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="facility-tabs-content"
          v-model="facilityTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <facility-view-tab-overview
              :facility-data="facilityData"
            ></facility-view-tab-overview>
          </v-tab-item>

          <v-tab-item>
            <facility-view-tab-inventory
              :facility-data="facilityData"
            ></facility-view-tab-inventory>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { mdiDomainPlus, mdiArchiveAlertOutline } from '@mdi/js'

import StoreModule from './StoreModule'
import FacilityViewBioPanel from './FacilityViewBioPanel.vue'
import FacilityViewTabOverview from './FacilityViewTabOverview.vue'
import FacilityViewTabInventory from './FacilityViewTabInventory.vue'

export default {
  components: {
    FacilityViewBioPanel,
    FacilityViewTabOverview,
    FacilityViewTabInventory,
  },
  setup() {
    const STORE_MODULE_NAME = 'facility'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, StoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const facilityTab = ref(null)
    const facilityData = ref({})
    const parentAvailable = ref(false)
    const branchAvailable = ref(false)
    const branchCount = ref(0)
    const facilityInventory = ref({})

    store
      .dispatch('facility/fetchFacility', { id: router.currentRoute.params.id })
      .then(response => {
        facilityData.value = response.data.result
        parentAvailable.value = response.data.result.parent.length > 0
        branchAvailable.value = response.data.result.branches.length > 0
        branchCount.value = response.data.result.branches.length
        facilityInventory.value = response.data.result.inventory
      })
      .catch(error => {
        if (error.response.status === 404) {
          facilityData.value = {}
        }
      })

    const tabs = [
      { icon: mdiDomainPlus, title: 'General' },
      { icon: mdiArchiveAlertOutline, title: 'Inventory' },
    ]

    return {
      tabs,
      facilityTab,
      facilityData,
      parentAvailable,
      branchAvailable,
      branchCount,
      facilityInventory,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
#facility-view #facility-tabs-content {
  background-color: transparent;
}
</style>
